import React, { useEffect, useState } from "react"
import { useAuth } from "@auth/useAuth"
import { tr } from "date-fns/locale"

const AllTokens = () => {
  const { makeRequest } = useAuth() || {}
  const [tokens, setTokens] = useState([])

  useEffect(() => {
    makeRequest("get-users-api-tokens")
      .then((res) => {
        if (res.tokens) setTokens(res.tokens)
      })
      .catch((e) => {
        console.log(e)
      })
  }, [])

  if (tokens.length === 0) {
    return <div />
  }
  return (
    <div>
      <h4 className={"mt-4"}>Created tokens</h4>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Token Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tokens.map((token, idx) => (
            <Tr key={idx} token={token} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

const Tr = ({ token }) => {
  const { makeRequest } = useAuth() || {}
  const [key, setKey] = useState("")
  const [progress, setProgress] = useState(false)
  const reveal = () => {
    setProgress(true)
    makeRequest("reveal-token", { id: token.id })
      .then((res) => {
        if (res.key) setKey(res.key)
        setProgress(false)
      })
      .catch((e) => {
        console.log(e)
        setProgress(false)
      })
  }
  return (
    <tr>
      <td>{token.name}</td>
      <td className={"text-right"}>
        <code>{key}</code>{" "}
        {key ? (
          <button
            className={"btn btn-primary btn-sm"}
            onClick={() => setKey("")}
          >
            Hide
          </button>
        ) : (
          <button
            className={"btn btn-primary btn-sm"}
            onClick={reveal}
            disabled={progress}
          >
            Show
          </button>
        )}
      </td>
    </tr>
  )
}

export default AllTokens
