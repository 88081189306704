import React, { useState } from "react"
import { useAuth } from "@auth/useAuth"
import { toast } from "react-toastify"

const CreateToken = () => {
  const { makeRequest } = useAuth() || {}

  const [name, setName] = useState(
    process.env.NODE_ENV === "development" ? "Test name" : ""
  )
  const [key, setKey] = useState("")
  const [progress, setProgress] = useState(false)

  const create = async () => {
    if (!name || progress) return
    setProgress(true)
    await makeRequest("create-user-api-token", { name })
      .then((res) => {
        if (res.key) setKey(res.key)
      })
      .catch((e) => {
        console.log(e)
        toast.warn("Failed to create the API key")
      })
    setProgress(false)
  }

  if (key) {
    return (
      <div>
        <p>Your API key:</p>
        <input type="text" className="form-control" value={key} readOnly />
      </div>
    )
  }
  return (
    <div>
      <h4>Create Token</h4>
      <div className="row">
        <div className="col-md-8 col-xl-6">
          <div className="form-group">
            <label htmlFor="">Name to identify the token</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={"Enter a name to identify the token"}
            />
            <button
              onClick={create}
              className="btn btn-primary mt-3 d-flex align-items-center justify-content-center"
              disabled={!name || progress}
            >
              {progress && (
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {progress ? "Creating Token" : "Create"}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateToken
