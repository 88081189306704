import React from "react"
import AllTokens from "./AllTokens"
import CreateToken from "./CreateToken"
import { useAuth } from "@auth/useAuth"

const Tokens = () => {
  const { hasScope } = useAuth() || {}

  if (!hasScope("create:space-operators") && !hasScope("use:public-api"))
    return null
  return (
    <div>
      <CreateToken />
      <AllTokens />
    </div>
  )
}

export default Tokens
