import React, { useEffect, useState } from "react"
import SEO from "@components/seo"
import Layout from "@components/layout"
import Tokens from "@components/Tokens"
import { useAuth } from "@auth/useAuth"

const TokensPage = () => {
  const { isAuthenticated } = useAuth() || {}

  const [pageReady, setPageReady] = useState(false)

  useEffect(() => {
    if (isAuthenticated) {
      setPageReady(true)
    }
  }, [isAuthenticated])

  return (
    <>
      <SEO title={"Manage Tokens"} />
      <Layout title={"Manage Tokens"}>
        {pageReady ? <Tokens /> : <div />}
      </Layout>
    </>
  )
}
export default TokensPage
